<template>
  <div class="focusList">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source>
      <div class="navTitle" v-if="userInfo.uid != this.$route.query.uid">他的粉丝</div>
      <div class="navTitle" v-else>我的粉丝</div>
      <div class="rightBtn"></div>
    </div>

    <div class="focusListMain">
      <PullRefresh :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
        @onRefresh="onRefresh" :isHigehtMax="true" :isNoData="isNoData" :error="error">
        <div class="userItem" v-for="(item) in list" :key="item.id">
          <router-link tag="div" :to="'/userHomepage?uid=' + item.uid" class="userAvatar">
            <ImgDecypt class="avatarImg" :src="item.portrait" :key="item.portrait" round/>
          </router-link>
          <div class="infoBox">
            <div class="userName">{{item.name}}</div>
            <div class="introduction">{{item.summary || '这个人很懒，还没有个性签名'}}</div>
          </div>
          <div class="follow" :class="{followed: item.isFollow}" @click="careOrcancle(item)">
            <svg-icon v-if="!item.isFollow" icon-class="followAdd"></svg-icon>
            {{item.isFollow ? '已关注' : '关注'}}
          </div>
          <!-- <div class="focusBtn" :class="{followedBtn: item.isFollow}"  @click="careOrcancle(item)">{{item.isFollow ? "已关注" : "关注"}}</div> -->
        </div>
      </PullRefresh>
    </div>


  </div>
</template>

<script>
  import {
    queryFanList
  } from "@/api/mine";
  import {
    careOrcancle
  } from "@/api/user";
  import ImgDecypt from "@/components/ImgDecypt";
  import PullRefresh from "@/components/PullRefresh";
  import { mapGetters } from "vuex";
  import {
    Toast
  } from 'vant';
  export default {
    components: {
      ImgDecypt,
      PullRefresh
    },
    data() {
      return {
        list: [],
        pageNumber: 1,
        pageSize: 10,
        loading: true,
        refreshing: false,
        finished: false,
        error: false,
        isNoData: false,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: "userInfo",
      }),
    },
    created() {
      this.getList();
    },
    methods: {
      // 返回
      backPage() {
        this.$router.go(-1);
      },
      // 查询列表
      async getList(type) {
        let req = {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          uid: this.$route.query.uid ? this.$route.query.uid : undefined
          // uid: 224411
        }
        try {
          let res = await this.$Api(queryFanList, req);
          console.log(res);
          this.loading = false;
          this.refreshing = false;
          if (res.code === 200) {
            let list = res.data.list || [];
            list.forEach(i => {
              i.isFollow = i.hasFollow;
            });
            if (type === 'refresh') {
              this.list = list;
            } else {
              this.list = this.list.concat(list);
            }
            if (this.list.length <= 0) {
              this.isNoData = true
            }
            if (!res.data.hasNext) {
              this.finished = true;
              return;
            }
          } else {
            this.error = true;
          }
        } catch (error) {
          this.error = true;
          this.loading = false;
        }


      },
      // 上啦加载
      onLoad() {
        this.loading = true;
        if (this.error) {
          this.error = false;
        } else {
          this.pageNumber++
        }
        this.getList();
      },
      // 下拉刷新
      onRefresh() {
        this.pageNumber = 1;
        this.refreshing = true;
        this.finished = false;
        this.loading = true;
        this.getList("refresh");
      },
      // 关注-取消关注
      async careOrcancle(item) {
        let req = {
          followUID: parseInt(item.uid),
          isFollow: !item.isFollow,
        }
        console.log(req);
        this.$store.commit('app/SET_LOADING', true);
        try {
          let res = await this.$Api(careOrcancle, req);
          this.$store.commit('app/SET_LOADING', false);
          if (res.code === 200) {
            Toast(req.isFollow ? "关注成功" : "取消成功")
            item.isFollow = req.isFollow;

            return;
          }
          Toast(res.tip || `${req.isFollow ? '关注失败' : '取消失败'}`);
        } catch (error) {
          this.$store.commit('app/SET_LOADING', false);
          Toast('操作失败')
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .focusList {
    height: 100%;

    .navBar {
      height: 44px;
      padding: 0 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e6e6e6;

      .navArrow {
        width: 17px;
        height: 17px;
      }

      .navTitle {
        flex: 2;
        text-align: center;
        font-size: 18px;
        color: #000;
      }

      .rightBtn {
        width: 17px;
        height: 17px;
      }
    }

    .focusListMain {
      height: calc(100% - 44px);
      padding: 18px 12px 0;
      box-sizing: border-box;

      .userItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        .userAvatar {
          width: 72px;
          height: 72px;
          border-radius: 50%;
          overflow: hidden;

          /deep/ .vanImage {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: #130f3200 !important;
          }

          /deep/ .van-image__error {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #130f3200 !important;
          }

          /deep/ img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .infoBox {
          .userName {
            font-size: 18px;
            margin-bottom: 6px;
            color: #000;
          }

          .introduction {
            width: 200px;
            font-size: 15px;
            color: #827f9d;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .follow {
          width: 60px;
          height: 24px;
          font-size: 12px;
          border-radius: 25px;
          line-height: 24px;
          color: #94D6DA;
          background: #FFFFFF;
          text-align: center;
          border: 1px solid #94D6DA;
          // position: absolute;
          // bottom: -12px;
          // right: 16px;
          z-index: 3;
          box-sizing: border-box;
        }

        .followed {
          width: 60px;
          height: 25px;
          color: #FFFFFF;
          font-size: 12px;
          border: none;
          background: #B3B3B3;
        }

        .focusBtn {
          width: 60px;
          height: 24px;
          border-radius: 25px;
          line-height: 25px;
          font-size: 12px;
          color: #94D6DA;
          border: 1px solid #94D6DA;
          background: #fff;
          text-align: center;
        }

        .followedBtn {
          width: 60px;
          height: 25px;
          border-radius: 25px;
          line-height: 25px;
          font-size: 14px;
          color: #fff;
          background: #999999;
          border: none;
          text-align: center;
        }
      }
    }
  }
</style>